import { bubugamingicon, bubugamingdiv } from "../../../../assets/images";

const BubuGaming = () => {
  return (
    <div
      className="item-bubu-division-full item-bubu-division-full-bubugaming"
      id="secbubugaming"
    >
      <div
        className="item-bubu-division-full-logo wow slideInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s"
      >
        <div className="item-bubu-division-full-logo-icon">
          <div
            className="item-bubu-division-full-logo-icon-in wow fadeInUp"
            data-wow-delay="0.6s"
            data-wow-duration="0.8s"
          >
            <img src={bubugamingicon} alt="" />
          </div>
        </div>
      </div>
      <div className="item-bubu-division-full-desc">
        <div
          className="bg-division-info-full wow fadeIn"
          data-wow-delay="0.4s"
          data-wow-duration="0.8s"
        >
          <img src={bubugamingdiv} alt="" />
        </div>
        <div className="item-bubu-division-full-desc-table-cell">
          <div className="container-item-bubu-division-full-desc">
            <h1
              className="heading-title-bubu-division-full wow fadeInDown"
              data-wow-delay="0.6s"
              data-wow-duration="0.8s"
            >
              BUBU <br /> GAMING
            </h1>
            <div className="content-item-scroll-division-info scrollbar-inner">
              <div className="content-item-scroll-division-info-in">
                <h4
                  className="heading-subtitle-bubu-division-full wow fadeInRight"
                  data-wow-delay="0.8s"
                  data-wow-duration="0.8s"
                >
                  <b>
                    We help brands tap into the massive and lucrative esports
                    and gaming community in Indonesia
                  </b>
                </h4>
                <div
                  className="content-item-scroll-division-info-padding wow fadeInRight"
                  data-wow-delay="1.4s"
                  data-wow-duration="0.8s"
                >
                  <h4 className="heading-subtitle-bubu-division-full">
                    1. Enhancing the grass root of esports in Indonesia through
                    community events such as our esports online tournament: BEST
                    (Bubu Esports Tournament) Arena
                    <br />
                    2. Indonesia Major Esports event Best Tournament, a major
                    offline tournament that connect our community with the world
                    <br />
                    3. Esports content, creating an esports content, educational
                    esports content with excellent esports talent program
                    <br />
                    4. Esports team management. Managing Morph Team (The leading
                    PUBGM team in Indonesia)
                    <br />
                    5. Developing state of the art esports platform, community
                    platform caters all the community activities including
                    linkedin for esports with on demand educational content
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="wrap-bt-explore-bubu-division wow fadeInUp"
              data-wow-delay="1.8s"
              data-wow-duration="0.8s"
            >
              <a
                href="https://www.instagram.com/bubu.gaming"
                target="_blank"
                rel="noreferrer"
                className="bt-explore-bubu-division"
              >
                EXPLORE
              </a>
              {/* <a
                href="https://bubugaming.com"
                target="_blank"
                rel="noreferrer"
                className="bt-explore-bubu-division"
              >
                HIRE US
              </a> */}
            </div>
            <div className="social-media-per-division">
              <ul className="social-media-per-division-item">
                <li
                  className="wow fadeInRight"
                  data-wow-delay="0.6s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.facebook.com/best.tournament/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="0.9s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.youtube.com/channel/UCS-gqVJPG72q4bTTTjQHhKQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="1.2s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.instagram.com/bubu.gaming"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default BubuGaming;
