const ItemPeople = ({ quote, name, title, desc, image }) => {
  return (
    <div className="item">
      <div className="item-slider-people-info-section">
        <div className="people-avatar-img-full">
          <img src={image} alt={image} />
        </div>
        <div className="item-slider-people-info-section-left">
          <div className="item-slider-people-info-section-left-in">
            <div className="wrap-text-slider-people-tag-title">
              <h1 className="text-slider-people-tag-title">
                <span dangerouslySetInnerHTML={{ __html: quote }}></span>
              </h1>
            </div>
          </div>
        </div>
        <div className="item-slider-people-info-section-right">
          <div className="item-slider-people-info-section-right-in">
            <div className="container-item-slider-people-info-sec-right-c">
              <h2 className="heading-title-name-of-poeple-s">
                <span dangerouslySetInnerHTML={{ __html: name }}></span>
              </h2>
              <h4 className="subtitle-name-of-people-s">
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
              </h4>
              <div className="content-par-people-desc-sec scrollbar-style scrollbar-inner">
                <div className="content-par-people-desc-sec-in">
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: desc }}></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default ItemPeople;
