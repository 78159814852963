import React from 'react'
import Scroll from '../components/scroll'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from '../pages/home'
import People from '../pages/people'
import Contact from '../pages/contact'
import News from '../pages/news'
import NewsDetail from "../pages/news/detail"

const Routes = () => {
    return (
      <Router>
        <Scroll>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/people" component={People} />
            <Route path="/contact" component={Contact} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:slug" component={NewsDetail} />
          </Switch>
        </Scroll>
      </Router>
    )
}

export default Routes