import Item from "./item";

const Sidebar = ({ setSection }) => {
  const data = [
    {
      mtab: "all",
      text: "ALL",
    },
    {
      mtab: "bubugaming",
      text: "BUBU GAMING",
    },
    {
      mtab: "supergirls",
      text: "SUPERGIRLS IN TECH",
    },
    {
      mtab: "startupindonesia",
      text: "STARTUP INDONESIA",
    },
    {
      mtab: "labx",
      text: "LABX",
    },
  ];
  return (
    <div className="wrap-tab-section-menu-left-media">
      <h3
        className="title-heading-page-media-tab wow fadeInDown"
        data-wow-duration="0.7s"
      >
        MEDIA
      </h3>
      <ul className="item-tab-media-content">
        {data.map((item, key) => {
          return (
            <Item
              mtab={item.mtab}
              text={item.text}
              key={key}
              index={key}
              setSection={setSection}
            />
          );
        })}
      </ul>
      <select name="" id="" className="tab-mobile-media-item">
        <option
          value=""
          className="item-click-mobile-media-tab"
          data-mtab="all"
        >
          all
        </option>
        <option
          value=""
          className="item-click-mobile-media-tab"
          data-mtab="bubugaming"
        >
          BUBU GAMING
        </option>
        <option
          value=""
          className="item-click-mobile-media-tab"
          data-mtab="supergirls"
        >
          SUPERGIRLS IN TECH
        </option>
        <option
          value=""
          className="item-click-mobile-media-tab"
          data-mtab="startupindonesia"
        >
          STARTUP INDONESIA
        </option>
        <option
          value=""
          className="item-click-mobile-media-tab"
          data-mtab="labx"
        >
          LABX
        </option>
      </select>
    </div>
  );
};

export default Sidebar;
