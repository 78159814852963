const Part = (props) => {
    return (
        <div className="item-logo-our-ventures">
            <div className="item-logo-our-ventures-in">
                <img src={props.image} alt=""/>
                <div className="overlay-text-info-portfolio">
                    <div className="overlay-text-info-portfolio-in">
                        { props.desc }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Part
