import { ov1, ov2, ov3, ov4, ov5 } from "../../../assets/images";
import Part from "./parts";

const Venture = () => {
  const data = [
    // {
    //     image: ov1,
    //     target: '#secbubu'
    // },
    // {
    //     image: ov2,
    //     target: '#secsupergirl'
    // },
    {
      image: ov3,
      target: "#secstartupindonesia",
    },
    {
      image: ov4,
      target: "#seclabx",
    },
    {
      image: ov5,
      target: "#secbubugaming",
    },
  ];
  return (
    <>
      <div className="wrap-our-Ventures-logo target-scroll" id="ventures">
        <h2
          className="heading-title-our-ventures wow fadeInUp"
          data-wow-duration="0.8s"
        >
          OUR VENTURES
        </h2>
        <div
          className="wrap-item-logo-our-ventures wow fadeInUp"
          data-wow-duration="0.8s"
        >
          {data.map((item, key) => {
            return <Part target={item.target} image={item.image} key={key} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Venture;
