import React from "react";

const ModalFinish = ({ setModalFinish }) => {
  return (
    <div className="modal-wrapper">
      <div className="inner-modal">
        <div
          className="modal-close-button"
          onClick={() => setModalFinish(false)}
        >
          <div className="modal-close-button-inner">X</div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1>Thank you for submitting !</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFinish;
