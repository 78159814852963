import React from "react";
import {
  compass01,
  compass02,
  compass002,
  compass03,
  compass004,
  compass005,
  compass006,
  compass007,
  compass08,
} from "../../../../assets/images";

const Compass = () => {
  return (
    <div>
      <div className="item" style={{ overflow: "hidden" }}>
        <div className="item-slider-main-home">
          <div className="overlay"></div>
          <img src={compass01} alt="" />
          <div className="compass-image-side">
            <img src={compass002} alt="" />
          </div>
          <div className="compass-image-logo-1">
            <img src={compass03} alt="" />
          </div>

          <div className="compass-image-logo-2">
            <img src={compass004} alt="" />
          </div>

          <div className="compass-image-logo-3">
            <img src={compass005} alt="" />
          </div>
          <div className="compass-image-logo-4">
            <img src={compass006} alt="" />
          </div>
          <div className="compass-image-logo-5">
            <img src={compass007} alt="" />
          </div>
          <div className="compass-image-logo-6">
            <img src={compass08} alt="" />
          </div>
          <h4 className="compass-text-1">
            As a collaboration agency, LabX helped Compass, a local shoe brand
            known for its strength in creative storytelling collaborated with
            Tame Impala to release the limited-edition shoe collection
            Artificial Vibration. Internationally known as a psychedelic rock
            musician from Australia, Tame Impala made this collaboration with
            Compass as a new year's gift for its fans in Indonesia.{" "}
            <i>The Less I Know the Better</i>, <i>Yes I'm Changing</i>, and{" "}
            <i>Lost In Yesterday</i> are three of Tame Impala's songs that are
            represented by spectrums through this so-called “Artificial
            Vibration” collaboration.
            <br />
            <br />
            LabX took part as the lead agency, handling all the technical
            processes from approaching Tame Impala to connecting and managing
            several stakeholders, such as the record labels, artist management,
            media relations, event organizer, AR agency, and influencers.
          </h4>
          {/* <h4 className="compass-text-2">
            LabX took part as a project manager to handle all the technicals
            process from approaching to closing with several stakeholders, such
            as the record label, artist management, media, AR companies, and
            many more.
          </h4> */}
        </div>
      </div>
    </div>
  );
};

export default Compass;
