import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap.min.css'
import './assets/css/owl.carousel.css'
import './assets/css/animate.css'
import './assets/css/font-awesome.css'
import './assets/css/lightbox.css'
import './assets/css/magnific-popup.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/override.css'
// import $ from 'jquery'
// import './assets/js/jquery-3.3.1.min.js'
// import './assets/js/owl.carousel.js'
// import './assets/js/wow.min.js'
// import './assets/js/jquery.fitvids.js'
// import './assets/js/smoothscroll.js'
// import './assets/js/jquery-migrate-1.2.1.js'
// import './assets/js/jquery.magnific-popup.min.js'
// import './assets/js/main.js'
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
