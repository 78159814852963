import Footer from "../../components/layout/footer";
import Navbar from "../../components/layout/navbar";
import NewsPart from "../../components/news";
import $ from "jquery";
import { useEffect, useState } from "react";
import { getNews, getNewsBySection } from "../../service/lib/api";
import { useLocation } from "react-router-dom";
import Modal2 from "../../components/modal2";
import ModalFinish from "../../components/modalFinish";

const News = () => {
  const [news, setNews] = useState([]);
  const [section, setSection] = useState("ALL");
  const location = useLocation();
  const { hash } = window.location;
  const [modal, setModal] = useState(location.hash == "#form" ? true : false);
  const [modalFinish, setModalFinish] = useState(false);

  useEffect(() => {
    console.log("section ", section);
    if (section === "ALL") {
      getNews().then((response) => {
        setNews(response.data.news);
      });
    } else {
      getNewsBySection(section).then((response) => {
        console.log("updated ", response);
        setNews(response.data.news);
      });
    }
  }, [section]);

  useEffect(() => {
    // tab media desktop
    $(".item-tab-media-content li").on("click", function () {
      var nm = $(this).data("mtab");
      $(".item-tab-content-media-sec").removeClass(
        "item-tab-content-media-sec-active"
      );
      $(this).siblings("li").removeClass("active");
      $(this).addClass("active");
      $("#" + nm).addClass("item-tab-content-media-sec-active");
    });

    // tab media mobile
    $(".tab-mobile-media-item").on("change", function () {
      var abc = $("option:selected", this).data("mtab");
      $(".item-tab-content-media-sec").removeClass(
        "item-tab-content-media-sec-active"
      );
      $("#" + abc).addClass("item-tab-content-media-sec-active");
    });
  });

  useEffect(() => {
    getNews().then((response) => {
      console.log(response);
      setNews(response.data.news);
    });
  }, []);

  return (
    <div>
      <Navbar
        type={`wrap-main-menu-logo-top-fixed`}
        navbar={`news`}
        setModal={setModal}
        modal={modal}
      />
      <NewsPart news={news} setSection={setSection} />
      <Footer />

      {modal && (
        <Modal2
          location={location}
          setModal={setModal}
          modal={modal}
          modalFinish={modalFinish}
          setModalFinish={setModalFinish}
        />
      )}

      {modalFinish && <ModalFinish setModalFinish={setModalFinish} />}
    </div>
  );
};

export default News;
