import {
  gggbg,
  gggembrasing,
  gggkarakter,
  ggglogo,
  gggmain,
} from "../../../../assets/images";

const GoodGameGuild = () => {
  return (
    <div>
      <div className="item">
        <div className="item-slider-main-home" style={{ overflow: "hidden" }}>
          <img src={gggbg} alt="" />
          <div className="ggg-main">
            <img src={gggmain} alt="" />
          </div>
          <div className="ggg-logo">
            <img src={ggglogo} alt="" />
          </div>
          <div className="ggg-embrasing">
            <img src={gggembrasing} alt="" />
          </div>
          <h4 className="ggg-text-1">
            Good Games Guild is a Gaming Hub aiming to create the largest
            virtual world economy by sponsoring millions of play-to-earn gamers
            and investing in play-to-earn games along with their in-game assets.
            Furthermore, Good Games Guild is also building tools that will
            enhance the future of gaming and the meta-verse.
            <br />
            <br />
            As the official agency of the Good Games Guild, Bubu aims to
            introduce the Web 3 Industry to Indonesia Market through social
            media strategy and digital marketing activation in Indonesia.
          </h4>
          {/* <div className="concave-image-ozil">
            <img src={concave001} alt="" />
          </div>
          <div className="concave-image-logo-1">
            <img src={concave03} alt="" />
          </div>
          <div className="concave-image-logo-2">
            <img src={concave04} alt="" />
          </div>
          <div className="concave-image-logo-3">
            <img src={concave05} alt="" />
          </div>
          <h4 className="concave-text-1">
            LabX as part of Bubu.com, helped Concave launch the M10 Gradient
            Collection by making a marketing strategy and social media
            maintenance on Instagram. The collection was designed and directed
            by Mesut Özil, an internationally renowned footballer who is now
            supporting the Fenerbahçe S.K club.
          </h4>
          <h4 className="concave-text-2">
            As part of the Concave x M10 collaboration, LabX also helps
            organize, produce, and document Mesut Özil's visit to Jakarta,
            Indonesia. We've covered event organization, show management, event
            production, press release, media relations, video documentation, and
            video creation during Mesut Özil's activity in Jakarta, Indonesia.
          </h4> */}
        </div>
      </div>
    </div>
  );
};

export default GoodGameGuild;
