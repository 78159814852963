import { 
    lgastra,
    lgaxe,
    lgbjb,
    lgbukopin,
    lgfacebook,
    lgfwd,
    lggaruda,
    lgikea,
    lgindofood,
    lgindosat,
    lgintel,
    lgintime,
    lgmead,
    lgmu,
    lgoppo,
    lgphd,
    lgsnapchat,
    lgtelin,
    lgtelkomsel,
    lgtnc,
    lgunilever 
} from '../../../assets/images'
import Part from './parts'

const Portfolio = () => {
    const data = [
        { image:lgsnapchat, desc: 'Market & Growth Startegy' },
        { image:lgmu, desc: 'Social Media Maintenance' },
        { image:lggaruda, desc: 'Web-based Application' },
        { image:lgbukopin, desc: 'Website Revamp' },
        { image:lgfwd, desc: 'Mobile Application' },
        { image:lgbjb, desc: 'Logo Design' },
        { image:lgikea, desc: 'Social Media Consulting' },
        { image:lgindosat, desc: 'Event Management ' },
        { image:lgtelkomsel, desc: 'Digital Campaign' },
        { image:lgunilever, desc: 'Digital Campaign' },
        { image:lgindofood, desc: 'Social Media Maintenance' },
        { image:lgintel, desc: 'Digital Event Management & Social Media Maintenance' },
        { image:lgtelin, desc: 'Search Engine Marketing' },
        { image:lgastra, desc: 'Digital Campaign' },
        { image:lgtnc, desc: 'Social Media Maintenance & Website Revamp' },
        { image:lgphd, desc: 'Social Media Maintenance & Video Production' },
        { image:lgfacebook, desc: 'Web-base Application' },
        { image:lgmead, desc: 'Mobile Application' },
        { image:lgaxe, desc: 'Digital Campaign' },
        { image:lgoppo, desc: 'Ad Placement' },
        { image:lgintime, desc: 'Social Media Maintenance' }
    ]
    return (
        <div className="wrap-our-Ventures-logo target-scroll" id="clients">
			<h2 className="heading-title-our-ventures wow fadeInUp" data-wow-duration="0.8s">OUR PORTFOLIO</h2>
			<div className="wrap-item-logo-our-ventures wow fadeInUp" data-wow-duration="0.8s">
				
                {
                    data.map((item, key) => {
                        return <Part image={item.image} desc={item.desc} key={key}/>
                    })
                }
				
			</div>
		</div>
    )
}

export default Portfolio