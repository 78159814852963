import './App.css';
import Routes from './routes'
import { WOW } from "wowjs";
import { useEffect } from 'react';
import $ from 'jquery'

const wow = new WOW();
wow.init();

function App() {
  useEffect(() => {
    // button show menu mobile
    $(document).on('click','.bt-menu-burger-mobile',function () {
      console.log('test')
      $('.wrap-menu-responsive').addClass('wrap-menu-responsive-active');
      $('body').addClass('active-menu');
    });

    $(document).on('click','.close-menu',function(){ 
      $('.wrap-menu-responsive').removeClass('wrap-menu-responsive-active');
      $('body').removeClass('active-menu');
    })

    // button close menu mobile
    $(document).on('click','.bt-close-menu-bar-res',function(){ 
      $('.wrap-menu-responsive').removeClass('wrap-menu-responsive-active');
      $('body').removeClass('active-menu');
    });

    $('.item-tab-media-content li').on("click", function () {
      var nm = $(this).data('mtab');
      $('.item-tab-content-media-sec').removeClass('item-tab-content-media-sec-active');
      $(this).siblings('li').removeClass('active');
      $(this).addClass('active');
      $('#' + nm).addClass('item-tab-content-media-sec-active');
    });

    // tab media mobile
    $('.tab-mobile-media-item').on("change", function () {
      var abc = $('option:selected', this).data("mtab");
      $('.item-tab-content-media-sec').removeClass('item-tab-content-media-sec-active');
      $('#' + abc).addClass('item-tab-content-media-sec-active');
    });

    // menu active when scroll the section
    $(window).scroll(function () {
      $('.target-scroll').each(function () {
        if ($(window).scrollTop() + 100 >= $(this).offset().top) {
          var id = $(this).attr('id');
          $('ul.main-menu-top-right li a').parent().removeClass('active');
          $('ul.main-menu-responsive li a').parent().removeClass('active');
          $('.'+id).addClass('active')
          // $('ul.main-menu-top-right li a[href^="#' + id + '"]').parent().addClass('active');
        }
      });
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 40) {
        $(".wrap-main-menu-logo-top-home").addClass("wrap-main-menu-logo-top-home-active");
        $(".imglg-orange").show();
        $(".imglg-white").hide();
      } else {
        $(".wrap-main-menu-logo-top-home").removeClass("wrap-main-menu-logo-top-home-active");
        $(".imglg-orange").hide();
        $(".imglg-white").show();
      }
    });
    
  },[])
  return (
    <Routes />
  )
}

export default App;
