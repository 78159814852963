import { mubg } from '../../../../assets/images'

const Item4 = () => {
    return (
        <div>
            <div className="item">
                <div className="item-slider-main-home">
                    <img src={mubg} alt="" />

                    <div className="slider-home-text-info">
                        <div className="slider-home-text-info-in">
                            <div className="container-slider-home-text-info">
                                <h3 className="heading-title-text-slider-home1">
                                    CLIENT HIGHLIGHT
									</h3>
                                <h2 className="heading-title-text-slider-home">
                                    MANCHESTER <br /> UNITED
									</h2>
                                <p className="par-text-desc-slider-home par-text-desc-slider-home-cus-padding2">
                                    We have been connecting the most recognizable brand in the world of football with their passionate fans in Indonesia & Malaysia through digital marketing for the last 8 years till to date.
									</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item4
