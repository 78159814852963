import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { HashLink as LinkHash } from 'react-router-hash-link';
import { logoWhite, logoOrange } from "../../../assets/images";
import Modal from "../../modal/home";

const Navbar = (props) => {
  const { hash } = window.location;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [nav, setNav] = useState(props.navbar);

  useEffect(() => {
    const detectHash = () => {
      if (hash !== "") {
        setNav(hash.replace("#", ""));
      }
    };
    detectHash();
  }, [hash]);

  useEffect(() => {
    // console.log('nav ', nav)
  }, [nav, hash]);

  const handleClick = (e) => {
    console.log("e ", e.target.dataset);
    setNav(e.target.dataset.navbar);
  };

  return (
    <div>
      <div className={`wrap-main-menu-logo-top ${props.type}`}>
        <div className="wrap-main-menu-logo-top-in">
          <div className="main-logo-top">
            <Link to="/">
              <img src={logoWhite} alt="" className="mlogonormal" />
              <img src={logoOrange} alt="" className="mlogoscroll" />
            </Link>
          </div>

          <div className="wrap-main-menu-top-right">
            <ul className="main-menu-top-right">
              <li>
                <a
                  href="#"
                  // onClick={() => props.setModal(!props.modal)}
                  data-toggle="modal"
                  data-target="#modalpopupform"
                  data-keyboard="false"
                >
                  BUBU 26
                </a>
              </li>
              <li
                className={
                  nav === "homeslider" ? `active homeslider` : "homeslider"
                }
              >
                <Link
                  to={`/`}
                  data-navbar="homeslider"
                  onClick={handleClick}
                  className="active"
                >
                  HIGHLIGHTS
                </Link>
              </li>
              <li
                className={nav === "ventures" ? `active ventures` : "ventures"}
                data-navbar="ventures"
                onClick={handleClick}
              >
                <Link
                  to={`/#ventures`}
                  data-navbar="ventures"
                  onClick={handleClick}
                >
                  VENTURES
                </Link>
              </li>
              <li className={nav === "clients" ? "active clients" : "clients"}>
                <Link
                  to={`/#clients`}
                  data-navbar="clients"
                  onClick={handleClick}
                >
                  CLIENTS
                </Link>
              </li>
              <li className={nav === "news" ? `active news` : "news"}>
                <Link to={`/news`} data-navbar="news" onClick={handleClick}>
                  NEWS
                </Link>
              </li>
              <li className={nav === "people" ? `active people` : "people"}>
                <Link to={`/people`} data-navbar="people" onClick={handleClick}>
                  PEOPLE
                </Link>
              </li>
              <li className={nav === "contact" ? `active contact` : "contact"}>
                <Link
                  to={`/contact`}
                  data-navbar="/contact"
                  onClick={handleClick}
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
          <div className="bt-menu-burger-mobile">
            <i className="fa fa-bars"></i>
          </div>
        </div>
      </div>
      <div className="wrap-menu-responsive">
        <div className="bt-close-menu-res">
          <i className="fa fa-close bt-close-menu-bar-res"></i>
        </div>
        <ul className="main-menu-responsive">
          {/* <li><a href="/#" onClick={handleShow} data-toggle="modal" data-target="#modalpopupform" data-keyboard="false">BUBU 25</a></li> */}
          <li
            className={
              nav === "homeslider"
                ? `active homeslider close-menu`
                : "homeslider close-menu"
            }
          >
            <Link
              to={`/`}
              data-navbar="homeslider"
              onClick={handleClick}
              className="active"
            >
              HIGHLIGHTS
            </Link>
          </li>
          {/* <li className={nav === 'ventures' ? `active` : ''}>
                        <LinkHash to={`/#ventures`}>VENTURES</LinkHash>
                    </li>
                    <li className={nav === 'clients' ? `active` : ''}>
                        <LinkHash to={`/#clients`}>CLIENTS</LinkHash>
                    </li>
                    <li className={nav === 'news' ? `active` : ''}>
                        <Link to={`/news`}>NEWS</Link>
                    </li>
                    <li className={nav === 'people' ? `active` : ''}>
                        <Link to={`/people`}>PEOPLE</Link>
                    </li>
                    <li className={nav === 'contact' ? `active` : ''} >
                        <Link to={`/contact`}>CONTACT</Link>
                    </li> */}
          <li
            className={nav === "ventures" ? `active ventures` : "ventures"}
            data-navbar="ventures"
            onClick={handleClick}
          >
            <Link
              to={`/#ventures`}
              data-navbar="ventures"
              onClick={handleClick}
            >
              VENTURES
            </Link>
          </li>
          <li className={nav === "clients" ? "active clients" : "clients"}>
            <Link to={`/#clients`} data-navbar="clients" onClick={handleClick}>
              CLIENTS
            </Link>
          </li>
          <li
            className={
              nav === "news" ? `active news close-menu` : "news close-menu"
            }
          >
            <Link to={`/news`} data-navbar="news" onClick={handleClick}>
              NEWS
            </Link>
          </li>
          <li
            className={
              nav === "people"
                ? `active people close-menu`
                : "people close-menu"
            }
          >
            <Link to={`/people`} data-navbar="people" onClick={handleClick}>
              PEOPLE
            </Link>
          </li>
          <li
            className={
              nav === "contact"
                ? `active contact close-menu`
                : "contact close-menu"
            }
          >
            <Link to={`/contact`} data-navbar="/contact" onClick={handleClick}>
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
      <Modal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Navbar;
