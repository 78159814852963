import { useEffect, useRef, useState } from "react";
import Content from "./content";
import LoadMore from "./loadmore";
import Sidebar from "./sidebar";

const News = ({ news, setSection }) => {
  const tab = ["all", "bubugaming", "supergirls", "startupindonesia", "labx"];
  const [visible, setVisible] = useState(4);
  const [buttonLoadMore, setButtonLoadMore] = useState(false);
  const containers = useRef(null);

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 4);
  };

  const scrollToBottom = () => {
    containers.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (news.length <= visible) {
      setButtonLoadMore(false);
    } else {
      setButtonLoadMore(true);
    }
    scrollToBottom();
  }, [visible, news]);
  //   const data = [
  //     {
  //       image: media1,
  //       text: "Tokopedia Kolaborasi dengan LABX Hadirkan Bazaar Online Hype Club 4.0",
  //       by: "By: Revivaltv.id",
  //     },
  //     {
  //       image: media2,
  //       text: "A Message to the Indonesian Youth",
  //       by: "",
  //     },
  //     {
  //       image: media3,
  //       text: "LabX Gallery",
  //       by: "",
  //     },
  //     {
  //       image: media4,
  //       text: "This Japanese Influence E-Bike Perfectly Pays Homage to The Past & The Future!",
  //       by: "By: Revivaltv.id",
  //     },
  //   ];
  return (
    <div className="wrap-section-contact-us-full">
      <div className="container-contact-us-section wrap-tab-media-content-section">
        <div className="wrap-sec-media-page-bubu">
          <Sidebar setSection={setSection} />

          <div
            className="wrap-tab-content-section-menu-right-media"
            ref={containers}
          >
            <div className="tab-content">
              {tab.map((item, key) => {
                return (
                  <div
                    className={`item-tab-content-media-sec ${
                      key === 0 ? `item-tab-content-media-sec-active` : ``
                    }`}
                    id={item}
                    key={key}
                  >
                    {news.length > 0 ? (
                      news.slice(0, visible).map((items, keys) => {
                        return (
                          <Content
                            image={items.image}
                            text={items.title}
                            category={items.category}
                            by={items.by}
                            url={items.url}
                            key={keys}
                            item={items}
                          />
                        );
                      })
                    ) : (
                      <div
                        className="alert alert-info"
                        style={{
                          height: "50%",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          <i className="fa fa-info-circle"></i> &nbsp; No
                          content under this section
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
              {buttonLoadMore && <LoadMore loadMore={loadMore} />}
            </div>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default News;
