const Footer = () => {
  let newDate = new Date();
  let year = newDate.getFullYear();
  return (
    <div className="footer-bar-bot">
      <div className="footer-bar-bot-in">
        <div className="wrap-social-media-footer">
          <ul className="social-media-footer">
            <li>
              <a href="/#">
                <i className="fa fa-google-plus"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/bubucom_/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/bubudotcom/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright-bar-bot">
          Copyright © {year} . BUBU.COM All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
