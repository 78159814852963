import { bubuicon, bubudiv } from "../../../../assets/images";

const Bubu = () => {
  return (
    <div
      className="item-bubu-division-full item-bubu-division-full-bubu"
      id="secbubu"
    >
      <div
        className="item-bubu-division-full-logo wow slideInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s"
      >
        <div className="item-bubu-division-full-logo-icon">
          <div
            className="item-bubu-division-full-logo-icon-in wow fadeInUp"
            data-wow-delay="0.6s"
            data-wow-duration="0.8s"
          >
            <img src={bubuicon} alt="" />
          </div>
        </div>
      </div>
      <div className="item-bubu-division-full-desc">
        <div
          className="bg-division-info-full wow fadeIn"
          data-wow-delay="0.4s"
          data-wow-duration="0.8s"
        >
          <img src={bubudiv} alt="" />
        </div>
        <div className="item-bubu-division-full-desc-table-cell">
          <div className="container-item-bubu-division-full-desc">
            <h1
              className="heading-title-bubu-division-full wow fadeInDown"
              data-wow-delay="0.6s"
              data-wow-duration="0.8s"
            >
              BUBU.COM <br />
              AGENCY
            </h1>
            <div className="content-item-scroll-division-info scrollbar-inner">
              <div className="content-item-scroll-division-info-in">
                <h4
                  className="heading-subtitle-bubu-division-full wow fadeInRight"
                  data-wow-delay="0.8s"
                  data-wow-duration="0.8s"
                >
                  <b>
                    Strategy and execution that connects people, brands, and the
                    digital world. We help transform our client's business
                    through the seamless integration of strategy, design and
                    technology
                  </b>
                </h4>
                <div
                  className="content-item-scroll-division-info-padding wow fadeInRight"
                  data-wow-delay="1.4s"
                  data-wow-duration="0.8s"
                >
                  {/* <h4 className="heading-subtitle-bubu-division-full">
                                        We help young local brands and talents to Identify their biggest opportunity at the moment and by collaboration will create opportunity for brand to excel and reach the top
                                    </h4> */}
                </div>
              </div>
            </div>
            <div
              className="wrap-bt-explore-bubu-division wow fadeInUp"
              data-wow-delay="1.8s"
              data-wow-duration="0.8s"
            >
              {/* <a href="https://bubu.com/agency" target="_blank" rel="noopener noreferrer" className="bt-explore-bubu-division">EXPLORE</a>
                            <a href="https://bubu.com/agency" target="_blank" rel="noopener noreferrer" className="bt-explore-bubu-division">HIRE US</a> */}
            </div>
            <div className="social-media-per-division">
              <ul className="social-media-per-division-item">
                <li
                  className="wow fadeInRight"
                  data-wow-delay="0.6s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.facebook.com/bubudotcom/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="0.9s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.linkedin.com/company/bubu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="1.2s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.instagram.com/bubucom_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="1.2s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.youtube.com/channel/UCHqg_Ref0i9CZnUIUY2IsUA/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default Bubu;
