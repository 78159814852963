import Item1 from "./slider/item1";
import Item2 from "./slider/item2";
import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
// import 'react-owl-carousel2/src/owl.theme.default.css'
import Item3 from "./slider/item3";
import Item4 from "./slider/item4";
import { useEffect } from "react";
import $ from "jquery";
import Concave from "./slider/concave";
import Compass from "./slider/compass";
import GoodGameGuild from "./slider/ggg";
import Bubu from "./slider/bubu26";

const Header = () => {
  const options = {
    items: 1,
    nav: true,
    navText: [
      "<div class='nav-btn-slide-home nav-btn-slide-home-prev-slide'><i class='fa fa-angle-left'></i></div>",
      "<div class='nav-btn-slide-home nav-btn-slide-home-next-slide'><i class='fa fa-angle-right'></i></div>",
    ],
    dots: false,
    rewind: true,
    autoplay: true,
    animateIn: "fadeIn",
    animateOut: "fadeOut",
    loop: true,
    autoplayTimeout: 6000,
    touchDrag: false,
    mouseDrag: false,
    margin: 0,
    autoplayHoverPause: false,
    autoHeight: false,
    center: true,
    smartSpeed: 100,
  };

  const events = {
    onDragged: function (event) {},
    onChanged: function (event) {},
  };

  useEffect(() => {
    $(document).ready(function () {
      $(".owl-carousel").addClass(
        "owl-center owl-loaded slider-main-header-home"
      );
      $(".owl-carousel").mouseover(function () {
        $(".owl-carousel").trigger("stop.owl.autoplay");
      });

      $(".owl-carousel").mouseleave(function () {
        $(".owl-carousel").trigger("play.owl.autoplay", [1000]);
      });
    });
  });

  return (
    <div className="wrap-header-slider-home target-scroll" id="homeslider">
      <OwlCarousel options={options} events={events}>
        <Bubu />
        <Concave />
        <Compass />
        <GoodGameGuild />
        <Item2 />
        <Item3 />
        <Item4 />
      </OwlCarousel>
    </div>
  );
};

export default Header;
