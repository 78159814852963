import { startupicon, startupbgdiv } from "../../../../assets/images";

const Startup = () => {
  return (
    <div
      className="item-bubu-division-full item-bubu-division-full-startup"
      id="secstartupindonesia"
    >
      <div
        className="item-bubu-division-full-logo wow slideInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s"
      >
        <div className="item-bubu-division-full-logo-icon">
          <div
            className="item-bubu-division-full-logo-icon-in wow fadeInUp"
            data-wow-delay="0.6s"
            data-wow-duration="0.8s"
          >
            <img src={startupicon} alt="" />
          </div>
        </div>
      </div>
      <div className="item-bubu-division-full-desc">
        <div
          className="bg-division-info-full wow fadeIn"
          data-wow-delay="0.4s"
          data-wow-duration="0.8s"
        >
          <img src={startupbgdiv} alt="" />
        </div>
        <div className="item-bubu-division-full-desc-table-cell">
          <div className="container-item-bubu-division-full-desc">
            <h1
              className="heading-title-bubu-division-full wow fadeInDown"
              data-wow-delay="0.6s"
              data-wow-duration="0.8s"
            >
              STARTUP <br /> INDONESIA
            </h1>
            <div className="content-item-scroll-division-info scrollbar-inner">
              <div className="content-item-scroll-division-info-in">
                <h4
                  className="heading-subtitle-bubu-division-full wow fadeInRight"
                  data-wow-delay="0.8s"
                  data-wow-duration="0.8s"
                >
                  <b>
                    An online platform looking to democratize resources to
                    startups
                  </b>
                </h4>
                <div
                  className="content-item-scroll-division-info-padding wow fadeInRight"
                  data-wow-delay="1.4s"
                  data-wow-duration="0.8s"
                >
                  <h4 className="heading-subtitle-bubu-division-full">
                    1. Startup directory (connect startups with investors)
                    <br />
                    2. Startup tools (curated list of vendors and partnerships
                    with more affordable pricing)
                    <br />
                    3. Startup education (mentoring, online masterclassName, and
                    women incubation program)
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="wrap-bt-explore-bubu-division wow fadeInUp"
              data-wow-delay="1.8s"
              data-wow-duration="0.8s"
            >
              <a
                href="https://startupindonesia.co/"
                target="_blank"
                rel="noreferrer"
                className="bt-explore-bubu-division"
              >
                EXPLORE
              </a>
            </div>

            <div className="social-media-per-division">
              <ul className="social-media-per-division-item">
                <li
                  className="wow fadeInRight"
                  data-wow-delay="0.6s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.linkedin.com/company/startupindonesia/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-delay="1.2s"
                  data-wow-duration="0.8s"
                >
                  <a
                    href="https://www.instagram.com/startupindonesia_co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default Startup;
