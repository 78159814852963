const LoadMore = ({ loadMore }) => {
  return (
    <div
      className="wrap-bt-show-more-media-box"
      onClick={loadMore}
      style={{ cursor: "pointer" }}
    >
      <a href={() => false} className="bt-show-more-media-box">
        SHOW MORE
      </a>
    </div>
  );
};

export default LoadMore;
