import React from "react";
import {
  bububg,
  bubutitle,
  bubusubtitle,
  bubulogo,
} from "../../../../assets/images";

const Bubu = () => {
  return (
    <div className="item" style={{ overflow: "hidden" }}>
      <div className="item-slider-main-home">
        <img src={bububg} alt="" />
        <div className="bubu-title">
          <img src={bubutitle} alt="" />
        </div>
        <div className="bubu-logo">
          <img src={bubulogo} alt="" />
        </div>
        <h4 className="bubu-desc">
          Established in 1996 with its humble beginning as a web development
          company, BUBU is now Indonesia&apos;s leading full-service digital
          agency. Bubu has been going strong as an impact maker for the past 26
          years and will continue to create more positive effects by optimizing
          Indonesia&apos;s digital economy opportunities.
          <br />
          <br />
          We take care of creativity & innovation seriously as we know that
          that&apos;s what brands & consumers both look for & pursue. We&apos;re
          now blooming as venture builders with a knack for digital marketing.
          We continue to take innovation to new heights from esports venture,
          interactive agency, collaboration agency, and startup incubator.
          Let&apos;s #CreateImpact by connecting and collaborating with us!
        </h4>
      </div>
    </div>
  );
};

export default Bubu;
