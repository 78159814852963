const Part = (props) => {
    return(
        <div className="item-logo-our-ventures">
            <div className="item-logo-our-ventures-in">
                <a href={props.target}><img src={props.image} alt=""/></a>
            </div>
        </div>
    )
}

export default Part