import React from 'react'
import Bubu from './bubu'
import BubuGaming from './bubugaming'
import Labx from './labx'
import Startup from './startup'
import SuperGirl from './supergirl'

const Division = () => {
    return (
		<div className="wrap-bubu-division-content target-scroll">
			<Bubu />
			<Startup />
			<BubuGaming />
			<SuperGirl />
			<Labx />	
		</div>
    )
}

export default Division
