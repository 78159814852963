import {
  concave01,
  concave001,
  concave02,
  concave03,
  concave04,
  concave05,
} from "../../../../assets/images";

const Concave = () => {
  return (
    <div>
      <div className="item">
        <div className="item-slider-main-home">
          <div className="overlay"></div>
          <img src={concave01} alt="" />
          <div className="concave-image-ozil">
            <img src={concave001} alt="" />
          </div>
          <div className="concave-image-logo-1">
            <img src={concave03} alt="" />
          </div>
          <div className="concave-image-logo-2">
            <img src={concave04} alt="" />
          </div>
          {/* <div className="concave-image-logo-3">
            <img src={concave05} alt="" />
          </div> */}
          <h4 className="concave-text-1">
            LabX, as the new collaborative division of Bubu.com, helped Concave
            to launch the M10 Gradient Collection by developing both marketing
            strategies and social media presence on Instagram. This special
            collection is designed and directed by Mesut Özil, a renowned
            international footballer who is now the captain of Turkish Süper Lig
            club Fenerbahçe club S.K.
            <br />
            <br />
            As part of the Concave x M10 collaboration, LabX also organized,
            produced, and executed Mesut Özil’s visit to Jakarta, Indonesia.
            Besides organizing several events during Ozil’s visit, LabX also
            handled the media relations by sharing the agenda of Mesut Özil
            through press releases, holding press conferences, and conducting
            exclusive media interviews with Mesut Özil.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Concave;
