import { useHistory } from "react-router-dom";

const Content = ({ text, category, url, image, by, item }) => {
  const history = useHistory()

  const handleClick = () => {
    if (item.body) {
      history.push(`/news/${item.slug}`)
    } else {
      window.open(item.url, "_blank")
    }
  }

  return (
    <div
      className={`item-media-box-rectangle-square item-media-box-${
        by !== "" ? `rectangle` : `square`
      }`}
      style={{cursor: 'pointer'}}
      onClick={handleClick}
    >
      <div className="item-media-box-rectangle-square-in">
        {/* <a href={url} target="_blank" rel="noreferrer"> */}
          <img src={image} alt="" />
          <div className="overlay-info-media-text-bar">
            <div className="overlay-info-media-text-bar-in">
              <h3 className="title-info-media-text-bar">{text}</h3>
            </div>
            {by !== "" ? (
              <div className="info-media-text-by">
                <div className="info-media-text-by-in">{by}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        {/* </a> */}
      </div>
    </div>
  );
};

export default Content;
