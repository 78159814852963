const Item = ({ index, mtab, text, setSection }) => {
  return (
    <>
      <li
        style={{ cursor: "pointer" }}
        className={`wow slideInLeft ${index === 0 ? `active` : ``}`}
        data-wow-duration="0.7s"
        data-mtab={mtab}
        onClick={() => setSection(text)}
      >
        <a href={() => false}>{text}</a>
      </li>
    </>
  );
};

export default Item;
