const SubFooter = () => {
    let newDate = new Date()
    let year = newDate.getFullYear();
    return (
        <div className="wrap-term-of-service-sec">
			<div className="link-term-of-service-privacy-policy">
				<a href="/#" className="bt-term-of-service-privacy-policy">Terms of Service</a>
				<a href="/#" className="bt-term-of-service-privacy-policy">Privacy Policy</a>
			</div>
			<h4 className="bubutagmeta">
				© {year} PT Bubu Kreasi Perdana
			</h4>
		</div>
    )
}

export default SubFooter
