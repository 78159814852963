import { shm1, emblembubu } from "../../../../assets/images";
import Modal from "../../../modal/home";
import { useState } from "react";

const Item2 = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      <div className="item">
        <div className="item-slider-main-home">
          <img src={shm1} alt={shm1} />

          {/* <div className="emblem-bubu25">
            <a href="/#" onClick={handleShow}>
              <img src={emblembubu} alt={emblembubu} />
            </a>
          </div> */}

          <div className="slider-home-text-info">
            <div className="slider-home-text-info-in">
              <div className="container-slider-home-text-info">
                <h2 className="heading-title-text-slider-home">
                  INFLUENTIAL,
                  <br /> INVINCIBLE
                </h2>
                <p className="par-text-desc-slider-home par-text-desc-slider-home-cus-padding1">
                  Since ‘96, we’ve been constantly transforming to go above and
                  beyond in the digital industry. From a world class digital
                  agency, we’re now a venture builder with a knack for digital
                  marketing. From esports to startups, we take innovation to new
                  heights!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Item2;
