import React from "react";
import { DefaultAlert } from "../../utils";
import {
  closebtregpop,
  popupbubulg,
  bubupopuplogoi,
} from "../../assets/images";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { submitHome } from "../../service/lib/api";
// require("dotenv").config();

const HomeModal = (props) => {
  const [years, setYears] = useState([]);
  const [data, setData] = useState({
    name: "",
    email: "",
    linkedIn: "",
    phoneNumber: "",
    currentJob: "",
    position: "",
    year: "",
    tellUs: "",
  });

  useEffect(() => {
    let temp = [];
    for (let index = 2021; index >= 1960; index--) {
      temp.push(index);
    }
    setYears(temp);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(data);
  };

  const handleSubmit = () => {
    if (data.email) {
      // axios.post(`${process.env.REACT_APP_SERVER}/users/bubu/sendemail`, data)
      // .then(res => {
      // 	console.log(res)
      // })
      submitHome(data).then((response) => {
        console.log("response ", response);
        DefaultAlert("Submit success", "Thank you", "success");
        window.location.reload();
      });
      // console.log('sending data... ',data.email)
      // console.log('Plugin email ', window.Email)
      // window.Email.send({
      // 	Host: "smtp.gmail.com",
      // 	Username: "febriansyah@bubu.com",
      // 	Password: "BukanAdmin2021#$",
      // 	To: '09febrian@gmail.com',
      // 	// To: ['formbubu25@bubu.com', 'tony.phangjaya@bubu.com'],
      // 	From: 'support@bubu.com',
      // 	Subject: "Form Contact bubu25",
      // 	Body: `
      // 		<h2>Contact form bubu25</h2>
      // 		<h4>Name : ${data.name}</h4>
      // 		<h4>email : ${data.email}</h4>
      // 		<h4>phoneNumber : ${data.phoneNumber}</h4>
      // 		<h4>linkedin : ${data.linkedIn}</h4>
      // 		<h4>job : ${data.job}</h4>
      // 		<h4>position : ${data.position}</h4>
      // 		<h4>year : ${data.year}</h4>
      // 		<h4>Tell Us : ${data.tellUs}
      // 	`
      // 	// html: "<h1>GeeksforGeeks</h1><p>A computer science portal</p>"
      // })
      // .then(function (message) {
      // 	console.log(message)
      // 	alert("Email sent")
      // }).catch(function (error){
      // 	console.log(error)
      // 	alert(error)
      // })
    } else {
      alert("email is required");
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleShow}
        className="custom-form-modal-popup"
        size="lg"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="box-modal-form-info">
                <div
                  className="bt-close-modal-reg-bubu25"
                  onClick={props.handleClose}
                >
                  <img src={closebtregpop} alt="" data-dismiss="modal" />
                </div>
                <div className="box-modal-form-info-left">
                  <div className="box-modal-form-info-left-in">
                    <div className="img-emblem-bubu-pop">
                      <img src={bubupopuplogoi} alt="" />
                    </div>
                    <div className="img-logo-form-bubu25">
                      <img src={popupbubulg} alt="" />
                    </div>
                    <h1 className="big-title-popup-bubu25-info">
                      Become a part of our army. Login with your personal info.
                    </h1>
                  </div>
                </div>
                {/* <form action="javascript:sendEmail()" method="POST"> */}
                <div className="box-modal-form-info-right">
                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">Name</h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="i-input-t-name-r"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">E-mail</h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="i-input-t-name-r"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">
                      LinkedIn Profile
                    </h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="linkedin"
                        name="linkedIn"
                        type="text"
                        className="i-input-t-name-r"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">Phone Number</h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="phone"
                        type="text"
                        name="phoneNumber"
                        className="i-input-t-name-r style-input-form-reg-best-number"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">Current Job</h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="job"
                        type="text"
                        name="currentJob"
                        className="i-input-t-name-r"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">
                      Position in BUBU (Alumni Only)
                    </h4>
                    <div className="item-input-t-name-reg-f">
                      <input
                        id="position"
                        type="text"
                        name="position"
                        className="i-input-t-name-r"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">
                      Year at BUBU (Alumni Only)
                    </h4>
                    <div className="item-input-t-name-reg-f">
                      <select
                        id="year"
                        name="year"
                        className="i-input-t-name-r2"
                        onChange={handleChange}
                      >
                        <option value="">- Choose -</option>
                        {years.map((item, key) => {
                          return (
                            <option value={item} key={key}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="item-form-input-bubu25-reg-info">
                    <h4 className="head-title-name-input-r">
                      Tell Us Who You Are
                    </h4>
                    <div className="item-input-t-name-reg-f">
                      <select
                        id="tell_us"
                        name="tellUs"
                        className="i-input-t-name-r2"
                        onChange={handleChange}
                      >
                        <option value="">- Choose -</option>
                        <option>Friend</option>
                        <option>Alumni</option>
                        <option>Client</option>
                        <option>Partner</option>
                      </select>
                    </div>
                  </div>
                  <div className="wrap-bt-submit-form-reg-bubu25-p">
                    <button
                      className="bt-submit-form-reg-bubu25-p"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/* </form> */}
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HomeModal;
