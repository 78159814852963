import React, { useState } from "react";
import { submitContact } from "../../service/lib/api";

const Modal2 = ({ location, setModal, modal, setModalFinish, modalFinish }) => {
  const [isError, setIsError] = useState(false);
  const [textError, setTextError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    companyName: null,
    email: null,
    phoneNumber: null,
    tellUs: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setTextError([]);
    setIsError(false);
    setLoading(true);
    if (data.companyName == null) {
      setTextError((current) => [...current, "Company name must not empty"]);
      setIsError(true);
      setLoading(false);
    }
    if (data.email == null) {
      setTextError((current) => [...current, "Email must not empty"]);
      setIsError(true);
      setLoading(false);
    }
    if (data.phoneNumber == null) {
      setTextError((current) => [...current, "Phone number must not empty"]);
      setIsError(true);
      setLoading(false);
    }
    if (data.tellUs == null) {
      setTextError((current) => [...current, "Tell us info must not empty"]);
      setIsError(true);
      setLoading(false);
    }

    if (!isError) {
      submitContact(data).then((response) => {
        setModal(false);
        setModalFinish(true);
      });
    }
  };
  return (
    <div className="modal-wrapper">
      <div className="inner-modal">
        <div className="modal-close-button" onClick={() => setModal(false)}>
          <div className="modal-close-button-inner">X</div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label style={{ color: "#ffffff" }}>Company Name :</label>
              <input
                type={`text`}
                name={`companyName`}
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label style={{ color: "#ffffff" }}>Email :</label>
              <input
                type={`email`}
                name={`email`}
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label style={{ color: "#ffffff" }}>Phone Number :</label>
              <input
                type={`text`}
                name={`phoneNumber`}
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label style={{ color: "#ffffff" }}>
                Tell us what you need :
              </label>
              <textarea
                rows={5}
                className="form-control"
                name="tellUs"
                onChange={handleChange}
              ></textarea>
            </div>
            {isError && (
              <div className="form-group">
                {textError.map((item, key) => (
                  <div className="alert alert-danger" key={key}>
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-12">
            <button className="btn btn-primary" onClick={handleSubmit}>
              {loading ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                `Submit`
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
