import React from 'react'

const Breadcrumb = ({ title }) => {
  return (
    <nav className="tw-flex" aria-label="Breadcrumb">
      <ol className="tw-inline-flex tw-items-center tw-space-x-1 md:tw-space-x-3 -tw-ml-7">
        <li className="tw-inline-flex tw-items-center">
          <a
            href="/"
            className="tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Home
          </a>
        </li>
        <li>
          <div className="tw-flex tw-items-center">
            <svg
              aria-hidden="true"
              className="tw-w-6 tw-h-6 tw-text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <a
              href="/news"
              className="tw-ml-1 tw-text-sm tw-font-medium tw-text-gray-700"
            >
              News
            </a>
          </div>
        </li>
        <li aria-current="page">
          <div className="tw-flex tw-items-center">
            <svg
              aria-hidden="true"
              className="tw-w-6 tw-h-6 tw-text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="tw-ml-1 tw-text-sm tw-font-medium tw-text-gray-500 tw-capitalize">
              {title}
            </span>
          </div>
        </li>
      </ol>
    </nav>
  )
}

export default Breadcrumb