import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/navbar"
import NewsPart from "../../components/news"
import $ from "jquery"
import { useEffect, useState } from "react"
import { getNews, getNewsBySection, getNewsBySlug } from "../../service/lib/api"
import { useHistory, useLocation, useParams } from "react-router-dom"
import Modal2 from "../../components/modal2"
import ModalFinish from "../../components/modalFinish"
import {bububg} from "../../assets/images"
import { FaRegUserCircle, FaRegClock } from "react-icons/fa"
import {HiOutlineTag} from "react-icons/hi"
import moment from "moment"
import "moment/locale/id" // without this line it didn't work
import Breadcrumb from "../../components/breadcrumb"
moment.locale("id")

const NewsDetail = () => {
  const {slug} = useParams()
  const history = useHistory()
  console.log('>> slug ', slug)
  const [news, setNews] = useState([])
  const [detail, setDetail] = useState(null)
  const [loading, setLoading] = useState(true)
  const [section, setSection] = useState("ALL")
  const location = useLocation()
  const { hash } = window.location
  const [modal, setModal] = useState(location.hash == "#form" ? true : false)
  const [modalFinish, setModalFinish] = useState(false)

  useEffect(() => {
    console.log("section ", section)
    if (section === "ALL") {
      getNews().then((response) => {
        setNews(response.data.news)
      })
    } else {
      getNewsBySection(section).then((response) => {
        console.log("updated ", response)
        setNews(response.data.news)
      })
    }
  }, [section])

  const getDetail = async () => {
    const res = await getNewsBySlug(slug)
    setDetail(res.data.news)
    setLoading(false)
  }

  useEffect(() => {
    getNews().then((response) => {
      console.log(response)
      setNews(response.data.news)
    })
    getDetail()
  }, [])

  console.log('>> detail ', detail)

  const handleClick = (item) => {
    if (item.body) {
      history.push(`/news/${item.slug}`)
    } else {
      window.open(item.url, '_blank')
    }
  }

  return !loading ? detail ? (
    <div>
      <Navbar
        type={`wrap-main-menu-logo-top-fixed`}
        navbar={`news`}
        setModal={setModal}
        modal={modal}
      />
      <section className="tw-h-full">
        <div className="tw-mx-auto tw-pt-[8.5rem] tw-max-w-7xl tw-px-5 xl:tw-px-0">
          <div className="tw-grid tw-grid-cols-5 tw-gap-10">
            <div className="tw-col-span-3">
              {/* BREADCRUMB */}
              <Breadcrumb title={detail?.title} />
              {/* BREADCRUMB */}

              {/* THUMBNAIL */}
              <div className="tw-h-[30rem] tw-rounded-2xl tw-overflow-hidden tw-w-full">
                <img
                  alt=""
                  src={detail?.image}
                  className="tw-object-cover tw-h-full tw-w-full"
                />
              </div>
              {/* THUMBNAIL */}

              {/* ATTRIBUTE */}
              <div className="tw-flex tw-flex-row tw-space-x-3 tw-items-center tw-text-sm tw-py-2">
                <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center">
                  <FaRegUserCircle />
                  <span>{detail.author ? detail.author : `Author`}</span>
                </div>
                <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center">
                  <FaRegClock />
                  <span>{moment().format("LL")}</span>
                </div>
                <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center">
                  <HiOutlineTag />
                  <span>{detail?.category}</span>
                </div>
              </div>
              {/* ATTRIBUTE */}
              <h1 className="tw-capitalize tw-text-2xl tw-py-3">
                {detail?.title}
              </h1>
              <div
                className="body tw-mb-20"
                dangerouslySetInnerHTML={{ __html: detail?.body }}
              ></div>
            </div>
            <div className="tw-col-span-2">
              <h2>Rekomendasi artikel</h2>
              <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-mt-5">
                {news
                  .slice(0, 5)
                  .filter((item2) => item2._id !== detail?._id)
                  .map((item, key) => (
                    <div
                      className="tw-flex tw-flex-row tw-space-x-3 tw-cursor-pointer"
                      key={key}
                      onClick={() => handleClick(item)}
                    >
                      <div className="tw-h-16 tw-w-16 tw-bg-purple-500 tw-rounded tw-overflow-hidden tw-shrink-0">
                        <img
                          src={item.image}
                          className="tw-object-cover tw-h-full tw-w-full"
                          alt=""
                        />
                      </div>
                      <div className="tw-grid tw-grid-cols-1 tw-gap-1">
                        <h1 className="tw-text-base tw-font-semibold">
                          {item.title}
                        </h1>
                        <div className="tw-flex tw-flex-row tw-space-x-2 tw-text-xs tw-items-center">
                          <FaRegClock />
                          <span>{moment(item.createdAt).format("LL")}</span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  ) : (
    <div className="alert alert-warning">
      Article not exist. Back to <a href="/news">news</a>
    </div>
  ) : null
}

export default NewsDetail
