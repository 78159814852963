import { labxdiv, labxicon } from "../../../../assets/images";

const Labx = () => {
  return (
    <div
      className="item-bubu-division-full item-bubu-division-full-labx"
      id="seclabx"
    >
      <div
        className="item-bubu-division-full-logo wow slideInLeft"
        data-wow-duration="0.8s"
        data-wow-delay="0.3s"
      >
        <div className="item-bubu-division-full-logo-icon">
          <div
            className="item-bubu-division-full-logo-icon-in wow fadeInUp"
            data-wow-delay="0.6s"
            data-wow-duration="0.8s"
          >
            <img src={labxicon} alt="" />
          </div>
        </div>
      </div>
      <div className="item-bubu-division-full-desc">
        <div
          className="bg-division-info-full wow fadeIn"
          data-wow-delay="0.4s"
          data-wow-duration="0.8s"
        >
          <img src={labxdiv} alt="" />
        </div>
        <div className="item-bubu-division-full-desc-table-cell">
          <div className="container-item-bubu-division-full-desc">
            <h1
              className="heading-title-bubu-division-full wow fadeInDown"
              data-wow-delay="0.6s"
              data-wow-duration="0.8s"
            >
              LABX
            </h1>
            <div className="content-item-scroll-division-info scrollbar-inner">
              <div className="content-item-scroll-division-info-in">
                <h4
                  className="heading-subtitle-bubu-division-full wow fadeInRight"
                  data-wow-delay="0.8s"
                  data-wow-duration="0.8s"
                >
                  <b>
                    'Incubate, accelerate, promote and upgrade of promising
                    brands and design creators
                  </b>
                </h4>
                <div
                  className="content-item-scroll-division-info-padding wow fadeInRight"
                  data-wow-delay="1.4s"
                  data-wow-duration="0.8s"
                >
                  <h4 className="heading-subtitle-bubu-division-full">
                    We help young local brands and talents to Identify their
                    biggest opportunity at the moment and by collaboration will
                    create opportunity for brand to excel and reach the top
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="wrap-bt-explore-bubu-division wow fadeInUp"
              data-wow-delay="1.8s"
              data-wow-duration="0.8s"
            >
              <a
                href="https://lab-x.co/"
                target="_blank"
                rel="noopener noreferrer"
                className="bt-explore-bubu-division"
              >
                EXPLORE
              </a>
              {/* <a
                href="https://lab-x.co/"
                target="_blank"
                rel="noopener noreferrer"
                className="bt-explore-bubu-division"
              >
                HIRE US
              </a> */}
            </div>

            <div className="social-media-per-division">
              <ul className="social-media-per-division-item">
                {/* <li
                    className="wow fadeInRight"
                    data-wow-delay="0.6s"
                    data-wow-duration="0.8s"
                  >
                    <a href="/#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li
                    className="wow fadeInRight"
                    data-wow-delay="0.9s"
                    data-wow-duration="0.8s"
                  >
                    <a href="/#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li> */}
                <li
                  className="wow fadeInRight"
                  data-wow-delay="1.2s"
                  data-wow-duration="0.8s"
                >
                  <a href="https://www.instagram.com/labx.co">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default Labx;
