import { useState } from "react";
import { submitContact } from "../../service/lib/api";
import { DefaultAlert } from "../../utils";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    subject: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (data.email) {
      submitContact(data).then((response) => {
        console.log(response);
        DefaultAlert("Submit success", "Thank you", "success");
      });
      // window.Email.send({
      // 	Host: "smtp.gmail.com",
      // 	Username: "dhimas.hertianto@bubu.com",
      // 	Password: "akugantengbangeth123",
      // 	To: ['formbubu25@bubu.com', 'dhimashertianto@gmail.com'],
      // 	From: 'support@bubu.com',
      // 	Subject: "Form Contact us bubu25",
      // 	Body: `
      // 		<h2>ContactUs form bubu25</h2>
      // 		<h4>Name : ${data.name}</h4>
      // 		<h4>email : ${data.email}</h4>
      // 		<h4>phoneNumber : ${data.phone}</h4>
      // 		<h4>subject : ${data.subject}</h4>
      // 		<h4>message : ${data.message}</h4>
      // 	`
      // 	// html: "<h1>GeeksforGeeks</h1><p>A computer science portal</p>"
      // })
      // .then(function (message) {
      // 	alert("Success")
      // }).catch(function (error){
      // 	alert(error)
      // })
    } else {
      alert("email is required");
    }
    console.log("sending data ", data);
  };

  return (
    <div className="wrap-section-contact-us-full">
      <div className="container-contact-us-section">
        <h2
          className="heading-title-contact-us-form wow fadeInLeft"
          data-wow-duration="0.8s"
        >
          CONNECT WITH US!
        </h2>
        <div className="padding-contac-us-form-sec">
          <div className="section-text-contact-info-detail">
            <div className="row custom-padding-contact">
              <div className="col-lg-6">
                <div
                  className="info-desc-sec-text-contact-us wow fadeInLeft"
                  data-wow-duration="0.8s"
                >
                  <h4>
                    Like our past works? Let’s talk about how Bubu can help you
                    get the most from digital marketing and new technologies.
                    We’re great listeners and always up for solving problems and
                    seeing opportunities. Let’s work together and create
                    results.
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="contact-info-email-sec wow fadeInRight"
                  data-wow-duration="0.8s"
                >
                  <h2>OUR CONTACT</h2>
                  <h4>HELLO@BUBU.COM</h4>
                </div>
              </div>
            </div>
          </div>
          <form>
            <div className="section-contact-form-connect">
              <div className="row custom-padding-contact">
                <div className="col-lg-6">
                  <div
                    className="wrap-input-form-connect-with-us wow fadeInLeft"
                    data-wow-duration="0.8s"
                  >
                    <div className="item-input-form-connect-with-us">
                      <div className="item-input-form-connect-with-us-title">
                        Name
                      </div>
                      <div className="item-input-form-connect-with-us-form">
                        <input
                          id="name"
                          name="name"
                          onChange={handleChange}
                          type="text"
                          className="input-f-connect-us-i"
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                    <div className="item-input-form-connect-with-us">
                      <div className="item-input-form-connect-with-us-title">
                        Subject
                      </div>
                      <div className="item-input-form-connect-with-us-form">
                        <input
                          id="subject"
                          name="subject"
                          onChange={handleChange}
                          type="text"
                          className="input-f-connect-us-i"
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="wrap-input-form-connect-with-us wow fadeInRight"
                    data-wow-duration="0.8s"
                  >
                    <div className="item-input-form-connect-with-us">
                      <div className="item-input-form-connect-with-us-title">
                        E-mail
                      </div>
                      <div className="item-input-form-connect-with-us-form">
                        <input
                          id="email"
                          name="email"
                          onChange={handleChange}
                          type="text"
                          className="input-f-connect-us-i"
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                    <div className="item-input-form-connect-with-us">
                      <div className="item-input-form-connect-with-us-title">
                        Phone Number
                      </div>
                      <div className="item-input-form-connect-with-us-form">
                        <input
                          id="phone"
                          name="phone"
                          onChange={handleChange}
                          type="text"
                          className="input-f-connect-us-i"
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row custom-padding-contact">
                <div className="col-lg-12">
                  <div
                    className="wrap-input-form-connect-with-us wow fadeInLeft"
                    data-wow-duration="0.8s"
                  >
                    <div className="item-input-form-connect-with-us">
                      <div className="item-input-form-connect-with-us-title">
                        Message
                      </div>
                      <div className="item-input-form-connect-with-us-form">
                        <textarea
                          name="message"
                          onChange={handleChange}
                          id=""
                          cols="30"
                          rows="10"
                          className="textarea-f-connect-us-i"
                        ></textarea>
                        <div className="wrap-bt-submit-connect-us">
                          <button
                            className="bt-submit-connect-us-cta"
                            onClick={handleSubmit}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
