import { useState } from "react";
import Navbar from "../../components/layout/navbar";
import Footer from "../../components/layout/footer";
import Contacts from "../../components/contact";
import { useLocation } from "react-router-dom";
import Modal2 from "../../components/modal2";
import ModalFinish from "../../components/modalFinish";

const Contact = () => {
  const location = useLocation();
  const { hash } = window.location;
  const [modal, setModal] = useState(location.hash == "#form" ? true : false);
  const [modalFinish, setModalFinish] = useState(false);
  return (
    <div>
      <Navbar
        type={`wrap-main-menu-logo-top-fixed`}
        navbar={`contact`}
        setModal={setModal}
        modal={modal}
      />
      <Contacts />
      <Footer />
      {modal && (
        <Modal2
          location={location}
          setModal={setModal}
          modal={modal}
          modalFinish={modalFinish}
          setModalFinish={setModalFinish}
        />
      )}

      {modalFinish && <ModalFinish setModalFinish={setModalFinish} />}
    </div>
  );
};

export default Contact;
