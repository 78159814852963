import axiosClient from "../axios";

export function submitContact(payload) {
  return axiosClient.post(`/api/submit-contact`, payload);
}

export function submitHome(payload) {
  return axiosClient.post(`/api/submit-home`, payload);
}

export function getPeople() {
  return axiosClient.get(`/api/people`);
}

export function getNews() {
  return axiosClient.get(`/api/news`);
}

export function getNewsBySection(section) {
  return axiosClient.get(`/api/news/id/${section}`);
}

export function getNewsBySlug(slug) {
  return axiosClient.get(`/api/news/detail/${slug}`)
}
