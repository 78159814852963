import { useEffect, useState } from "react";
import Navbar from "../../components/layout/navbar";
import Header from "../../components/home/header";
import Venture from "../../components/home/venture";
import Division from "../../components/home/division";
import Portfolio from "../../components/home/portfolio";
import SubFooter from "../../components/home/subfooter";
import Footer from "../../components/layout/footer";
import { useLocation } from "react-router-dom";
import Modal2 from "../../components/modal2";
import ModalFinish from "../../components/modalFinish";

const Home = () => {
  const location = useLocation();
  const { hash } = window.location;
  const [modal, setModal] = useState(location.hash == "#form" ? true : false);
  const [modalFinish, setModalFinish] = useState(false);

  useEffect(() => {
    const elementToScroll = document.getElementById(hash.replace("#", ""));
    if (!elementToScroll) return true;
    window.scrollTo({
      top: elementToScroll.offsetTop - 80,
      behavior: "smooth",
    });
  }, [hash]);
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <Navbar
          type={`wrap-main-menu-logo-top-home`}
          navbar={`homeslider`}
          setModal={setModal}
          modal={modal}
        />
        <Header />
        <Venture />
        <Division />
        <Portfolio />
        <SubFooter />
        <Footer />
        {modal && (
          <Modal2
            location={location}
            setModal={setModal}
            modal={modal}
            modalFinish={modalFinish}
            setModalFinish={setModalFinish}
          />
        )}

        {modalFinish && <ModalFinish setModalFinish={setModalFinish} />}
      </div>
    </>
  );
};

export default Home;
