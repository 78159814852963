import $ from "jquery";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { getPeople } from "../../service/lib/api";
import ItemPeople from "./parts";

const People = () => {
  const [people, setPeople] = useState([]);
  useEffect(() => {
    $(".owl-nav").addClass("bt-nav-slider-home2");
  }, [people]);
  const options = {
    items: 1,
    nav: true,
    navText: [
      "<div class='nav-btn-slide-home2 nav-btn-slide-home-prev-slide'><i class='fa fa-angle-left'></i></div>",
      "<div class='nav-btn-slide-home2 nav-btn-slide-home-next-slide'><i class='fa fa-angle-right'></i></div>",
    ],
    dots: false,
    rewind: true,
    autoplay: true,
    animateIn: "fadeIn",
    animateOut: "fadeOut",
    loop: true,
    autoplayTimeout: 6000,
    touchDrag: false,
    mouseDrag: false,
    margin: 0,
    autoplayHoverPause: false,
    autoHeight: false,
    center: true,
    smartSpeed: 700,
  };

  const events = {
    onDragged: function (event) {},
    onChanged: function (event) {},
  };

  useEffect(() => {
    getPeople().then((response) => setPeople(response.data.people));
  }, []);

  return (
    <div className="wrap-section-people-slider-full">
      <div className="section-people-slider-full">
        {people.length > 0 && (
          <OwlCarousel options={options} events={events}>
            {people.map((item, key) => (
              <ItemPeople
                key={key}
                name={item.name}
                title={item.title}
                quote={item.quote}
                desc={item.desc}
                image={item.image}
              />
            ))}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default People;
