import { snapchatbg, snapchaticon } from '../../../../assets/images'

const Item3 = () => {
    return (
        <div>
            <div className="item">
                <div className="item-slider-main-home">
                    <img src={snapchatbg} alt="" />
                    <div className="snapchat-icon-slider">
                        <img src={snapchaticon} alt="" />
                    </div>

                    <div className="slider-home-text-info">
                        <div className="slider-home-text-info-in">
                            <div className="container-slider-home-text-info">
                                <h3 className="heading-title-text-slider-home1">
                                    CLIENT HIGHLIGHT
							</h3>
                                <h2 className="heading-title-text-slider-home">
                                    SNAPCHAT
							</h2>
                                <p className="par-text-desc-slider-home par-text-desc-slider-home-cus-padding2">
                                    We are helping the global social media giant, Snapchat, with go-to-market & growth strategy in Indonesia. Our team of digital analysts help prepare market trend research and give local perspective on their strategy
							</p>
                                <p className="par-text-desc-slider-home par-text-desc-slider-home-cus-padding2">
                                    Moreover, we also help to identify and establish local partnerships with Indonesia’s ecosystem to hack their growth in the #1 digital economy in Southeast Asia
							</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item3
